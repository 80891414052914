import Rest from '../Rest'

export default class InstitutionService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/institutions'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  addUser(institutionId, user) {
    return this.post(`/${institutionId}/users`, {user}).then(
      (data) => {
        return data
      }
    )
  }

  removeUser(institutionId, userId) {
    return this.delete(`/${institutionId}/users/${userId}`).then(
      (data) => {
        return data
      }
    )
  }

  /**
   * Warning: Depends on ACTIVE institution context.
   */
  getUserStudentIdentifier(userId) {
    return this.get(`/active/students/${userId}/identifier`)
  }
}
