var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: "institutions.create",
              expression: "'institutions.create'",
            },
          ],
          staticClass: "flex flex-wrap-reverse items-center",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary",
              on: { click: _vm.addNew },
            },
            [
              _c("feather-icon", {
                attrs: { icon: "PlusIcon", svgClasses: "h-4 w-4" },
              }),
              _c("span", { staticClass: "ml-2 text-base text-primary" }, [
                _vm._v(_vm._s(_vm.$t("AddNew"))),
              ]),
            ],
            1
          ),
        ]
      ),
      _c("Grid", {
        attrs: {
          service: _vm.service,
          route_name: "institutions",
          column_formats: {
            organization_id: function (data, rowData) {
              return _vm._.get(rowData, "organization.name", "") || ""
            },
          },
        },
        scopedSlots: _vm._u([
          {
            key: "gridThead",
            fn: function () {
              return [
                _c("vs-th", { key: "id", attrs: { "sort-key": "id" } }, [
                  _vm._v("ID"),
                ]),
                _c("vs-th", { key: "name", attrs: { "sort-key": "name" } }, [
                  _vm._v(_vm._s(_vm.$t("name"))),
                ]),
                _c(
                  "vs-th",
                  { key: "organization_id", attrs: { "sort-key": null } },
                  [_vm._v(_vm._s(_vm.$t("organization")))]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }